import { useParams } from "@remix-run/react";
import { useEffect } from "react";

import { WILogo } from "~/components/icons/wi-logo";
import { InitialFeedbackForm } from "~/components/initial-feedback-form";
import { NegativeFeedbackForm } from "~/components/negative-feedback-form";
import { PositiveFeedbackForm } from "~/components/positive-feedback-form";
import { ResponsiveDialog } from "~/components/ui/responsive-dialog";
import { useFeedback } from "~/hooks/dialog-context";
import { useFeedbackDismissed } from "~/hooks/useFeedbackDismissed";
import { CONTENT } from "~/lib/config";
import { segment } from "~/lib/segment";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export function FeedbackDialog(props: Props) {
  const params = useParams();
  const { openFinalDialog, feedbackStage, setFeedbackStage } = useFeedback();
  const [_, setFeedbackDismissed] = useFeedbackDismissed();

  useEffect(() => {
    // Dismiss final stage after 2 seconds
    if (feedbackStage === "done") {
      const dismiss = setTimeout(() => {
        props.setOpen(false);
        openFinalDialog();
      }, 2_500);

      // Reset feedback stage
      const reset = setTimeout(() => {
        setFeedbackStage("initial");
      }, 3_000);

      return () => {
        clearTimeout(reset);
        clearTimeout(dismiss);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackStage]);

  function closeDialogAndTrack(open: boolean) {
    if (!open) {
      segment.track("Feedback Dialog Dismissed", { feedbackStage, date: params.date });
      setFeedbackDismissed(true);
      openFinalDialog();
    }
    props.setOpen(open);
  }

  return (
    <ResponsiveDialog open={props.open} setOpen={closeDialogAndTrack} title="Feedback" hideTitle>
      <div>
        <div className="flex items-center gap-2">
          <WILogo className="size-7" />
          <span className="text-lg font-bold uppercase">THE WORSHIP INITIATIVE</span>
        </div>
        <div className="mt-6">
          {feedbackStage === "initial" ? <InitialFeedbackForm /> : null}
          {feedbackStage === "positive" ? <PositiveFeedbackForm /> : null}
          {feedbackStage === "negative" ? <NegativeFeedbackForm /> : null}
          {feedbackStage === "done" ? (
            <div className="mx-auto w-full space-y-4 text-center">
              <h2>{CONTENT.feedbackDone.title}</h2>
              <p className="text-sm text-muted-foreground">{CONTENT.feedbackDone.subtitle}</p>
            </div>
          ) : null}
        </div>
      </div>
    </ResponsiveDialog>
  );
}
