import { useParams } from "@remix-run/react";
import { useState } from "react";

import { useFeedback } from "~/hooks/dialog-context";
import { usePhoneEntered } from "~/hooks/usePhoneEntered";
import { CONTENT } from "~/lib/config";
import { segment } from "~/lib/segment";
import { cn } from "~/lib/utils";

export function InitialFeedbackForm() {
  const params = useParams();
  const [phoneEntered] = usePhoneEntered();
  const [value, setValue] = useState<number>(0);
  const { setFeedbackStage } = useFeedback();

  function handleFeedback() {
    segment.track("Feedback Rating", { value, date: params.date });
    if (phoneEntered) {
      setFeedbackStage("done");
      return;
    }

    if (value > 2) {
      setFeedbackStage("positive");
    } else {
      setFeedbackStage("negative");
    }
  }

  return (
    <div className="mx-auto w-full">
      <p className="text-pretty text-3xl font-bold">{CONTENT.initialFeedback.title}</p>
      <p className="mt-1 text-xl font-light text-muted-foreground">{CONTENT.initialFeedback.subtitle}</p>
      <div
        aria-describedby="feedback-label"
        className="mx-auto mt-6 flex w-full flex-row-reverse justify-between gap-1"
      >
        {Array.from({ length: 5 })
          .map((_, index) => 5 - index)
          .map((btnValue) => {
            return (
              <button
                key={`rating-${btnValue}`}
                data-button-value={btnValue}
                onClick={() => setValue(btnValue)}
                className={cn(
                  "relative grid aspect-square max-w-16 grow place-items-center rounded-full border-2 text-lg font-bold transition",
                  btnValue === value
                    ? "border-foreground bg-foreground text-background"
                    : "hover:bg-foreground/5 dark:border-muted-foreground",
                )}
              >
                <span className="absolute top-16 text-[13px] font-medium text-muted-foreground">
                  {CONTENT.initialFeedback.ratingText[btnValue - 1]}
                </span>
                {btnValue}
              </button>
            );
          })}
      </div>
      <button
        disabled={!value}
        onClick={handleFeedback}
        className="mt-10 w-full rounded-lg border-2 border-foreground bg-foreground px-4 py-3 font-bold text-background transition disabled:opacity-40 sm:py-2"
      >
        {CONTENT.initialFeedback.buttonText}
      </button>
    </div>
  );
}
