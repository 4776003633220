import { useRouteLoaderData } from "@remix-run/react";

import { loader } from "~/routes/daily.$date";

export function useDailyData() {
  const parentData = useRouteLoaderData<typeof loader>("routes/daily.$date");
  if (!parentData) {
    throw new Error(
      "useDailyData must be used within the routes/daily.$date route. If you are inside the correct route, then there is an issue with the loader.",
    );
  }
  return parentData;
}
