export function AlbumArt() {
  return (
    <img
      sizes="(max-width: 1400px) 100vw, 1400px"
      srcSet="
      /album_art_gkxuyp_c_scale,w_200.png 200w,
      /album_art_gkxuyp_c_scale,w_354.png 354w,
      /album_art_gkxuyp_c_scale,w_471.png 471w,
      /album_art_gkxuyp_c_scale,w_569.png 569w,
      /album_art_gkxuyp_c_scale,w_647.png 647w,
      /album_art_gkxuyp_c_scale,w_720.png 720w,
      /album_art_gkxuyp_c_scale,w_782.png 782w,
      /album_art_gkxuyp_c_scale,w_850.png 850w,
      /album_art_gkxuyp_c_scale,w_913.png 913w,
      /album_art_gkxuyp_c_scale,w_959.png 959w,
      /album_art_gkxuyp_c_scale,w_994.png 994w,
      /album_art_gkxuyp_c_scale,w_1040.png 1040w,
      /album_art_gkxuyp_c_scale,w_1102.png 1102w,
      /album_art_gkxuyp_c_scale,w_1143.png 1143w,
      /album_art_gkxuyp_c_scale,w_1198.png 1198w,
      /album_art_gkxuyp_c_scale,w_1248.png 1248w,
      /album_art_gkxuyp_c_scale,w_1287.png 1287w,
      /album_art_gkxuyp_c_scale,w_1288.png 1288w,
      /album_art_gkxuyp_c_scale,w_1357.png 1357w,
      /album_art_gkxuyp_c_scale,w_1400.png 1400w"
      src="/album_art_gkxuyp_c_scale,w_1400.png"
      alt="Album art of The Worship Initiative's collaborate album with TGCW24"
    />
  );
}
