import { createContext, useContext, useState } from "react";

import { FeedbackDialog } from "~/components/feedback-dialog";
import { FinalDialog } from "~/components/final-dialog";

type Stage = "positive" | "negative" | "done" | "initial";
const initialState = {
  feedbackStage: "initial",
  feedbackDialogOpen: false,
  finalDialogOpen: false,
  setFeedbackStage: (_: Stage) => {},
  openFeedbackDialog: () => {},
  closeFeedbackDialog: () => {},
  openFinalDialog: () => {},
  closeFinalDialog: () => {},
};

const FeedbackContext = createContext(initialState);

function DialogProvider({ children }: { children: React.ReactNode }) {
  const [feedbackStage, setFeedbackStage] = useState<"positive" | "negative" | "done" | "initial">("initial");
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [finalDialogOpen, setFinalDialogOpen] = useState(false);

  function openFeedbackDialog() {
    setFeedbackDialogOpen(true);
  }

  function closeFeedbackDialog() {
    setFeedbackDialogOpen(false);
  }

  function openFinalDialog() {
    setFinalDialogOpen(true);
  }

  function closeFinalDialog() {
    setFinalDialogOpen(false);
  }

  const value = {
    feedbackStage,
    setFeedbackStage,
    feedbackDialogOpen,
    finalDialogOpen,
    openFeedbackDialog,
    closeFeedbackDialog,
    openFinalDialog,
    closeFinalDialog,
  };
  return (
    <FeedbackContext.Provider value={value}>
      <>
        {children}
        <FeedbackDialog open={feedbackDialogOpen} setOpen={setFeedbackDialogOpen} />
        <FinalDialog open={finalDialogOpen} setOpen={setFinalDialogOpen} />
      </>
    </FeedbackContext.Provider>
  );
}

function useFeedback() {
  const context = useContext(FeedbackContext);
  if (context === undefined) {
    throw new Error("useFeedback must be used within a FeedbackProvider");
  }
  return context;
}

export { DialogProvider as FeedbackProvider, useFeedback };
