import { useParams } from "@remix-run/react";
import { E164Number } from "libphonenumber-js/core";
import { useState } from "react";
import PhoneInput from "react-phone-number-input/input";

import { useFeedback } from "~/hooks/dialog-context";
import { usePhoneEntered } from "~/hooks/usePhoneEntered";
import { CONTENT } from "~/lib/config";
import { segment } from "~/lib/segment";

export function PositiveFeedbackForm() {
  const params = useParams();
  const [, setPhoneEntered] = usePhoneEntered();
  const [phone, setPhone] = useState<E164Number>();
  const { setFeedbackStage } = useFeedback();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    segment.track("Positive Feedback Submitted", { phone, date: params.date });
    setPhoneEntered(true);
    // TODO: Submit phone number to HubSpot
    setFeedbackStage("done");
  }

  return (
    <div className="mx-auto w-full space-y-4">
      <p className="text-pretty font-bold">{CONTENT.positiveFeedback.title}</p>
      <p className="mt-1 text-sm font-light text-muted-foreground">{CONTENT.positiveFeedback.subtitle}</p>
      <form className="space-y-4" onSubmit={handleSubmit}>
        <label htmlFor="phone" className="sr-only">
          Phone Number
        </label>
        <PhoneInput
          name="phone"
          id="phone"
          country="US"
          value={phone}
          onChange={setPhone}
          placeholder="(555) 555-5555"
          className="block w-full rounded-md border-0 px-3.5 py-4 shadow-sm ring-1 ring-inset ring-muted-foreground transition placeholder:text-muted-foreground/50 invalid:ring-[] focus:ring-2 focus:ring-inset focus:ring-muted-foreground sm:text-sm sm:leading-6 dark:text-background"
        />
        <button className="w-full rounded-lg border-2 border-foreground bg-foreground px-4 py-3 font-medium text-background sm:py-2">
          {CONTENT.positiveFeedback.buttonText}
        </button>
      </form>
      <div>
        <a
          href="https://theworshipinitiative.com/privacy-policy"
          rel="noreferrer"
          target="_blank"
          className="text-xs text-muted-foreground/50 underline"
        >
          {CONTENT.positiveFeedback.privacyLinkText}
        </a>
      </div>
    </div>
  );
}
