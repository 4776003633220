import { useParams } from "@remix-run/react";
import { useState } from "react";

import { useFeedback } from "~/hooks/dialog-context";
import { CONTENT } from "~/lib/config";
import { segment } from "~/lib/segment";

export function NegativeFeedbackForm() {
  const params = useParams();
  const [details, setDetails] = useState("");
  const { setFeedbackStage } = useFeedback();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    segment.track("Negative Feedback Submitted", { details, date: params.date });
    // TODO: Submit feedback to HubSpot
    setFeedbackStage("done");
  }

  return (
    <div className="mx-auto w-full space-y-4">
      <p className="text-pretty text-2xl font-bold">{CONTENT.negativeFeedback.title}</p>
      <p className="mt-1 text-sm font-light text-muted-foreground">{CONTENT.negativeFeedback.subtitle}</p>
      <form className="space-y-4" onSubmit={handleSubmit}>
        <label htmlFor="details" className="sr-only">
          Details
        </label>
        <textarea
          name="details"
          id="details"
          placeholder={CONTENT.negativeFeedback.placeholderText}
          value={details}
          onChange={(e) => setDetails(e.target.value)}
          rows={4}
          maxLength={500}
          className="block w-full rounded-md border-0 px-3.5 py-4 shadow-sm ring-1 ring-inset ring-muted-foreground transition placeholder:text-muted-foreground/50 focus:ring-2 focus:ring-inset focus:ring-muted-foreground sm:text-sm sm:leading-6"
        />
        <span className="mt-1 block text-right text-sm">{details.length} / 500</span>
        <button className="w-full rounded-lg border-2 border-foreground bg-foreground px-4 py-3 font-bold text-background sm:py-2">
          {CONTENT.negativeFeedback.buttonText}
        </button>
      </form>
    </div>
  );
}
