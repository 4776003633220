export const VIDEO_TRACKING_INTERVAL_MS = 1_000;
export const SECONDS_REMAINING_TO_COMPLETE = 10;
export const VIDEO_TRACKING_PERCENTAGE_INTERVAL = 2.5;

export const CONTENT = {
  initialFeedback: {
    title: "We want your feedback!",
    subtitle: "How was your experience?",
    ratingText: ["Poor", "Fair", "Good", "Great", "Excellent"],
    buttonText: "Submit",
  },
  positiveFeedback: {
    title: "We're partnering with organizations like TGC to make song-based devotionals accessible to everyone.",
    subtitle: "Sign up below to get updates on our progress!",
    buttonText: "Stay Updated",
    privacyLinkText: "Privacy Policy",
  },
  negativeFeedback: {
    title: "We're sorry to hear that.",
    subtitle: "Please take a moment to tell us how we can improve. Your response is anonymous.",
    placeholderText: "Leave feedback",
    buttonText: "Submit",
  },
  feedbackDone: {
    title: "Thank you for your feedback!",
    subtitle: "We appreciate your time.",
  },
  finalDialog: {
    listenAgainButtonText: "Listen Again",
    exploreSongButtonText: "Explore Song",
  },
  eventFinishedScreen: {
    title: "We hope you enjoy TGCW24!",
    subtitle: "Continue to worship with us using the links below.",
    buttons: {
      streamNow: {
        text: "Stream Now",
        color: "primary",
        // TODO: Link to the spotify page for the album
        href: "https://www.catapultdistribution.com/magnet/The-Gospel-Coalition/Behold-%26-Believe",
      },
      exploreMore: {
        text: "Explore More",
        color: "secondary",
        // TODO: Make sure this links to the correct page
        href: "https://resources.theworshipinitiative.com/behold-and-believe",
      },
      listenAgain: {
        text: "Listen Again",
        color: "secondary",
        // TODO: Make sure this is the first day of the event
        href: "/daily/2024-06-13",
      },
      provideFeedback: {
        text: "Provide Feedback",
        color: "secondary",
      },
    },
  },
} as const;
