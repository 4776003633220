import { ComponentPropsWithoutRef } from "react";

export function WILogo(props: ComponentPropsWithoutRef<"svg">) {
  return (
    <svg {...props} width="472" height="472" viewBox="0 0 472 472" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <style type="text/css">
          {`
            .logo {
              fill: #333;
            }
            :root[data-theme="dark"] .logo {
              fill: #fff;
            }
            @media screen and (prefers-color-scheme: dark) {
              .logo {
                fill: #a1a1aa80;
              }
            }
          `}
        </style>
      </defs>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className="logo"
        d="M235.968 472C366.289 472 471.937 366.338 471.937 236C471.937 105.661 366.289 0 235.968 0C105.647 0 0 105.661 0 236C0 366.338 105.647 472 235.968 472ZM160.289 215.679L234.834 136.154L309.357 216.249L405.004 169.058L307.438 334.712L235.401 253.658L163.363 334.712L65.7988 169.058L160.289 215.679Z"
      />
    </svg>
  );
}
