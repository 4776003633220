import { Link, useParams } from "@remix-run/react";
import { X } from "lucide-react";

import { AlbumArt } from "~/components/album-art";
import { WILogo } from "~/components/icons/wi-logo";
import { ResponsiveDialog } from "~/components/ui/responsive-dialog";
import { useDailyData } from "~/hooks/useDailyData";
import { CONTENT } from "~/lib/config";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export function FinalDialog(props: Props) {
  const params = useParams();
  const { eventDays } = useDailyData();

  const dateParam = params.date as string;
  const selectedDayFromData = eventDays.find((day) => day.date === dateParam);

  return (
    <ResponsiveDialog
      {...props}
      title="Thank You"
      description="Thank you for attending TGCW24 and watching our daily video. We have partnered with The Worship Initiative to bring you this content."
      hideTitle
      hideDescription
    >
      <div className="flex flex-col items-center gap-y-4">
        <div className="flex items-center gap-2">
          <img src="/tgcw24-rose.svg" alt={`TGCW24 Logo`} className="mt-4" />
          <X className="size-5 text-muted-foreground/50" />
          <WILogo className="size-10" />
        </div>
        <div>
          {/* Album art */}
          <div className="size-48 overflow-hidden rounded-md">
            <AlbumArt />
          </div>
        </div>
        <div className="mt-4 grid grid-cols-2 gap-2">
          <Link
            className="rounded-lg border-2 border-foreground bg-transparent px-4 py-2 font-bold"
            to={`/daily/${selectedDayFromData?.date ?? eventDays[0].date}`}
            onClick={() => props.setOpen(false)}
          >
            {CONTENT.finalDialog.listenAgainButtonText}
          </Link>
          {selectedDayFromData ? (
            <a
              className="flex items-center justify-center whitespace-nowrap rounded-lg bg-primary px-2 py-2 font-bold text-primary-foreground"
              // TODO: Make sure this links to the correct page
              href={`https://resources.theworshipinitiative.com/behold-and-believe`}
              target="_blank"
              rel="noreferrer"
            >
              {CONTENT.finalDialog.exploreSongButtonText}
            </a>
          ) : null}
        </div>
      </div>
    </ResponsiveDialog>
  );
}
