import { ReactNode } from "react";

import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "~/components/ui/dialog";
import { cn } from "~/lib/utils";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  icon?: ReactNode;
  children: ReactNode;
  description?: string;
  hideTitle?: boolean;
  hideDescription?: boolean;
};

export function ResponsiveDialog(props: Props) {
  // const isDesktop = useMediaQuery("(min-width: 768px)");

  // if (isDesktop) {
  return (
    <Dialog open={props.open} onOpenChange={props.setOpen}>
      <DialogContent>
        <DialogHeader>
          {props.icon}
          <DialogTitle className={cn("text-center", props.hideTitle && "sr-only")}>{props.title}</DialogTitle>
          <DialogDescription className={cn("text-center", props.hideDescription && "sr-only")}>
            {props.description}
          </DialogDescription>
        </DialogHeader>
        {props.children}
      </DialogContent>
    </Dialog>
  );
}
